import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BodyText from "../Article/BodyText";
import PostMeta from "./PostMeta";

const StyledPost = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 700px;
	background: #f4f4f4;
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const Post = ({ post, category }) => {
	const {
		body,
		frontmatter: { title, author, lang, date }
	} = post;
	return (
		<StyledPost>
			<h1>{title}</h1>
			<PostMeta date={date} author={author} category={category} lang={lang} />
			<BodyText body={body} />
		</StyledPost>
	);
};

Post.propTypes = {
	post: PropTypes.shape({
		body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
		frontmatter: PropTypes.shape({
			title: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			date: PropTypes.string.isRequired,
			author: PropTypes.string.isRequired,
			category: PropTypes.string.isRequired,
			lang: PropTypes.string.isRequired,
			cover: PropTypes.object.isRequired
		}).isRequired,
		headings: PropTypes.array.isRequired
	}).isRequired,
	category: PropTypes.shape({
		title: PropTypes.string.isRequired,
		categoryName: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired
	}).isRequired
};

export default Post;
