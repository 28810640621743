import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Post from "../components/Blog/Post";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import ContentContainer from "../components/ContentContainer";

require("prismjs/themes/prism-okaidia.css");

const EnhancedContentContainer = styled(ContentContainer)`
	p code[class*="language-"] {
		background: #d1d1d0;
		line-height: 1.4;
		text-shadow: initial;
		color: initial;
	}
`;

const BlogPost = ({ data, pageContext }) => {
	const { title, description, lang } = data.post.frontmatter;
	const category = data.category.frontmatter;
	return (
		<Layout pageContext={pageContext}>
			<SEO title={title} description={description} lang={lang} />
			<EnhancedContentContainer>
				<Post post={data.post} category={category} />
			</EnhancedContentContainer>
		</Layout>
	);
};

BlogPost.propTypes = {
	data: PropTypes.shape({
		post: PropTypes.shape({
			id: PropTypes.string.isRequired,
			body: PropTypes.string.isRequired,
			frontmatter: PropTypes.shape({
				title: PropTypes.string.isRequired,
				description: PropTypes.string.isRequired,
				url: PropTypes.string.isRequired,
				date: PropTypes.string.isRequired,
				author: PropTypes.string.isRequired,
				category: PropTypes.string.isRequired,
				lang: PropTypes.string.isRequired,
				cover: PropTypes.object.isRequired
			}).isRequired,
			headings: PropTypes.array.isRequired
		}).isRequired,
		category: PropTypes.shape({
			frontmatter: PropTypes.shape({
				title: PropTypes.string.isRequired,
				categoryName: PropTypes.string.isRequired,
				url: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	}).isRequired,
	pageContext: PropTypes.object.isRequired // eslint-disable-line
};

export default BlogPost;

export const postQuery = graphql`
	query PostByURL($url: String!, $category: String!, $lang: String!) {
		post: mdx(frontmatter: { url: { eq: $url }, lang: { eq: $lang } }) {
			id
			body
			frontmatter {
				title
				description
				url
				date
				author
				category
				lang
				cover {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
		category: mdx(
			frontmatter: { categoryName: { eq: $category }, lang: { eq: $lang } }
		) {
			frontmatter {
				categoryName
				title
				url
			}
		}
	}
`;
