import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MDXRenderer } from "gatsby-plugin-mdx";

const StyledBodyText = styled.div`
	animation-name: BodyTextEntry;
	animation-duration: 1s;

	h2,
	h3 {
		padding: 1.5em 0px 1em;
		margin: 0;
	}

	h2 {
		line-height: 1.2;
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.35em;
		line-height: 1.3;
	}

	p {
		font-size: 1em;
		line-height: 1.2;
	}
	&.styled-reference p {
		padding: 0px 0px 1em;
	}
	ul {
		list-style: circle;
		margin: 0 0 1.5em;
		padding: 0 0 0 1.5em;
	}
	li {
		margin: 0 0 0.5em;
		line-height: 1.5;
	}
	a {
		color: #2aad7c;
		text-decoration: underline;
	}
	figure,
	& > .gatsby-resp-image-wrapper {
		margin: 1em 0;
	}
	figcaption {
		padding: 0.5em;
		text-align: center;
	}
	blockquote {
		background-color: #ffffff;
		margin: 0 0 1.5em;
		padding: 20px 40px;
		font-style: italic;
		p:last-child {
			margin: 0;
		}
	}
	hr {
		opacity: 0;
		clear: both;
		height: 0;
		margin: 0;
	}

	@keyframes BodyTextEntry {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const BodyText = ({ body, className, ...rest }) => {
	if (typeof body === "string")
		return (
			<StyledBodyText className={`${className} body-text`} {...rest}>
				<MDXRenderer>{body}</MDXRenderer>
			</StyledBodyText>
		);

	return (
		<StyledBodyText className={`${className} body-text`} {...rest}>
			{body}
		</StyledBodyText>
	);
};

BodyText.propTypes = {
	body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
	className: PropTypes.string
};

BodyText.defaultProps = {
	className: ""
};

export default BodyText;
